import Axios from '@/plugins/axios.js'
import Store from '@/plugins/store.js'
import Cookies from '@/plugins/cookies.js'
import Shared from '@/mixins/shared.js'
import Search from '@/mixins/search.js'
import Autocomplete from '@/mixins/autocomplete.js'
import MzListItem from '@/custom/HitListItem.vue'
import MzGridItem from '@/custom/HitGridItem.vue'
import MzHitItem from '@/custom/MzHitItem.vue'
import MzLazyItem from '@/custom/MzLazyItem.vue'
import MzHtmlGridItem from '@/custom/HitHtmlGridItem.vue'
import Vue from 'vue'
import VueBus from 'vue-bus'
import VueScrollTo from 'vue-scrollto'
import PortalVue from 'portal-vue'
import LineClamp from 'vue-line-clamp'
import ChDrawer from 'ch-drawer'
import { mapState } from 'vuex'
import Translation from '@/plugins/translation.js'
import VueObserveVisibility from 'vue-observe-visibility'

// import styles for ui-components
import '@mezereon/ui-components-vue/dist/ui-components-vue.css'
import '@/style/index.scss'

import '@/plugins/QueryState.js'
import '@/plugins/filters.js'

// import custom content
import '@/custom/style.scss'

import {
  MzActionBar,
  MzSortBy,
  MzFilterBtn,
  MzPaging,
  MzToggleLayout,
  MzHitsInfo,
  MzBanner,
  MzList,
  MzTree,
  MzHitsGrid,
  MzHitsList,
  MzPagination,
  MzSlider,
  MzRefinements,
  MzSelections,
  MzTooltip
} from '@mezereon/ui-components-vue'

import Recommendations from './custom/Recommendations.vue'
import MzShopifySearchBox from './custom/MzShopifySearchBox.vue'
import MzAutocomplete from './custom/MzAutocomplete.vue'
import MzImageSearch from './custom/MzImageSearch.vue'
import MzHitsContainer from './custom/MzHitsContainer.vue'
import MzInstantSearchBox from './custom/MzInstantSearchBox.vue'
import { Fragment } from 'vue-frag'
import frag from 'vue-frag'

window.mz = window.mz || {}

Vue.use(VueScrollTo)
Vue.use(VueBus)
Vue.use(Axios)
Vue.use(Store)
Vue.use(Cookies)
Vue.use(Translation)
Vue.use(PortalVue)
Vue.use(LineClamp)
Vue.config.productionTip = false
Vue.use(ChDrawer, { zIndex: 99999 })
Vue.use(VueObserveVisibility)

Vue.component('Fragment', Fragment)

if (document.getElementById('mz-autocomplete')) {
  window.mz.suggest = new Vue({
    components: {
      MzAutocomplete,
      MzImageSearch,
      MzInstantSearchBox
    },
    mixins: [Shared, Autocomplete],
    methods: {
      visibilityChanged(isVisible, entry, self) {
        if (!isVisible && self && self.popper) {
          self.hidePopper()
        }
      }
    }
  }).$mount('#mz-autocomplete')
}

if (document.getElementById('mz-app')) {
  window.mz.initializeVueApp = () => {
    return new Vue({
      el: '#mz-app',
      components: {
        'mz-search-box': MzShopifySearchBox,
        MzListItem,
        MzGridItem,
        MzHitItem,
        MzLazyItem,
        MzHtmlGridItem,
        MzActionBar,
        MzSortBy,
        MzFilterBtn,
        MzPaging,
        MzToggleLayout,
        MzHitsInfo,
        MzBanner,
        MzList,
        MzTree,
        MzHitsGrid,
        MzHitsList,
        MzHitsContainer,
        MzPagination,
        MzSlider,
        MzRefinements,
        MzSelections,
        MzTooltip
      },
      directives: {
        frag
      },
      mixins: [Shared, Search],
      data() {
        return {
          portalNav: false
        }
      },
      computed: {
        filterLayoutClass() {
          return `mz-filter-layout-${this.filterLayout}`
        },
        ...mapState('search', ['filterLayout'])
      },
      methods: {
        togglePortalNav(show) {
          this.portalNav = show
        }
      }
    })
  }
  window.mz.app = mz.initializeVueApp()
}

// create mz-recs dynamically
if (!document.getElementById('mz-recs')) {
  const mzRecs = document.createElement('div')
  mzRecs.id = 'mz-recs'
  document.body.append(mzRecs)
}

if (document.getElementById('mz-recs')) {
  window.mz.recs = new Vue({
    mixins: [Shared],
    render: (h) => h(Recommendations)
  }).$mount('#mz-recs')
}

document.addEventListener('DOMContentLoaded', function () {
  // placeholder
})
