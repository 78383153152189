<template>
  <Fragment>
    <slot />
  </Fragment>
</template>

<script>
import { debounce } from 'lodash'
export default {
  props: {
    selector: {
      type: String,
      required: false,
      default: 'input[name="q-instant-search"]'
    },
    delay: {
      type: Number,
      required: false,
      default: 200
    }
  },
  name: 'MzInstantSearchBox',
  mounted() {
    const itemList = document.querySelectorAll(this.selector)
    let inputs = []
    Array.from(itemList).forEach(function (item) {
      inputs.push(item)
    })
    let self = this
    for (const match of inputs) {
      match.addEventListener('keyup', (event) => {
        self.runSearch(self, match)
      })
    }
  },
  watch: {
    delay: {
      handler(newValue) {
        this.runSearch = debounce((vm, input) => {
          vm.doSearch(input)
        }, newValue)
      },
      immediate: true
    }
  },
  methods: {
    clearKeyword() {
      this.$store.dispatch('search/setKeyword', '', { root: true })
      this.$store.dispatch('search/setPage', 1, { root: true })
      if (this.$bus) this.$bus.emit('run-query')
    },
    runSearch() {
      return
    },
    doSearch(input) {
      let value = input.value
      if (value.length == 0) {
        this.clearKeyword()
        return
      }
      this.$store.dispatch('search/setKeyword', value, { root: true })
      this.$store.dispatch('search/setPage', 1, { root: true })
      if (this.$bus) this.$bus.emit('run-query')
    }
  }
}
</script>
